import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    exportOperator,
    ExportOperatorPayload,
    exportOperators,
    exportOperatorsPlanning,
    generateNewPassword,
    list,
    listAbsences,
    listAbsencesByType,
    listShifts,
    listTaskGroups,
    OperatorCreatePayload,
    OperatorIdPayload,
    OperatorListAbsencesByTypePayload,
    OperatorListAbsencesByTypeResponse,
    OperatorListAbsencesPayload,
    OperatorListAbsencesResponse,
    OperatorListPayload,
    OperatorListResponse,
    OperatorListShiftsPayload,
    OperatorListShiftsResponse,
    OperatorListTaskGroupsPayload,
    OperatorListTaskGroupsResponse,
    OperatorPlanningAvailabilitiesPayload,
    OperatorPlanningAvailabilitiesResponse,
    OperatorUpdateMobilicTokenPayload,
    OperatorUpdatePayload,
    planningAvailabilities,
    remove,
    sendMobilicEmail,
    update,
    updateMobilicToken,
} from './api/operators';
import { Operator } from './api/types';

export const operatorsKeys = {
    all: ['operators'],
    lists: () => [...operatorsKeys.all, 'list'],
    list: (params?: OperatorListPayload) => [...operatorsKeys.lists(), params],
    details: () => [...operatorsKeys.all, 'details'],
    detail: (id?: OperatorIdPayload) => [...operatorsKeys.details(), id],
    listShifts: (params: OperatorListShiftsPayload) => [...operatorsKeys.all, 'listShifts', params],
    listTaskGroups: (params: OperatorListTaskGroupsPayload) => [...operatorsKeys.all, 'listTaskGroups', params],
    listAbsencesAll: () => [...operatorsKeys.all, 'listAbsencesAll'],
    listAbsences: (params: OperatorListAbsencesPayload) => [...operatorsKeys.listAbsencesAll(), 'listAbsences', params],
    listAbsencesByType: (params: OperatorListAbsencesByTypePayload) => [
        ...operatorsKeys.listAbsencesAll(),
        'listAbsencesByType',
        params,
    ],
    planningAvailabilities: (params: OperatorPlanningAvailabilitiesPayload) => [
        ...operatorsKeys.all,
        'planningAvailabilities',
        params,
    ],
    export: () => [...operatorsKeys.all, 'export'],
    exportOperator: (operatorId: ExportOperatorPayload) => [...operatorsKeys.export(), 'operators', operatorId],
    sendMobilicEmail: (params: OperatorIdPayload) => [...operatorsKeys.all, 'sendMobilicEmail', params],
    exportOperatorsPLanning: (params: OperatorPlanningAvailabilitiesPayload) => [
        ...operatorsKeys.all,
        'exportPlanning',
        params,
    ],
};

export const useOperatorList = <TData = OperatorListResponse>(
    params?: OperatorListPayload,
    options?: UseQueryOptions<OperatorListResponse, AxiosError, TData>
) => {
    return useQuery<OperatorListResponse, AxiosError, TData>(
        operatorsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorDetails = <TData = Operator>(
    id?: OperatorIdPayload,
    options?: UseQueryOptions<Operator, AxiosError, TData>
) => {
    return useQuery<Operator, AxiosError, TData>(operatorsKeys.detail(id), async () => await details(id), options);
};

export const useOperatorCreate = (options?: UseMutationOptions<Operator, AxiosError, OperatorCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Operator, AxiosError, OperatorCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(operatorsKeys.lists());
        },
    });
};

export const useOperatorUpdate = (options?: UseMutationOptions<Operator, AxiosError, OperatorUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Operator, AxiosError, OperatorUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(operatorsKeys.detail(variables.id));
            queryClient.invalidateQueries(operatorsKeys.lists());
        },
    });
};

export const useOperatorRemove = (options?: UseMutationOptions<undefined, AxiosError, OperatorIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, OperatorIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(operatorsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(operatorsKeys.lists());
        },
    });
};

export const useOperatorListShifts = <TData = OperatorListShiftsResponse>(
    params: OperatorListShiftsPayload,
    options?: UseQueryOptions<OperatorListShiftsResponse, AxiosError, TData>
) => {
    return useQuery<OperatorListShiftsResponse, AxiosError, TData>(
        operatorsKeys.listShifts(params),
        async () => await listShifts(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorListTaskGroups = <TData = OperatorListTaskGroupsResponse>(
    params: OperatorListTaskGroupsPayload,
    options?: UseQueryOptions<OperatorListTaskGroupsResponse, AxiosError, TData>
) => {
    return useQuery<OperatorListTaskGroupsResponse, AxiosError, TData>(
        operatorsKeys.listTaskGroups(params),
        async () => await listTaskGroups(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorListAbscences = <TData = OperatorListAbsencesResponse>(
    params: OperatorListAbsencesPayload,
    options?: UseQueryOptions<OperatorListAbsencesResponse, AxiosError, TData>
) => {
    return useQuery<OperatorListAbsencesResponse, AxiosError, TData>(
        operatorsKeys.listAbsences(params),
        async () => await listAbsences(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorListAbscencesByType = <TData = OperatorListAbsencesByTypeResponse>(
    params: OperatorListAbsencesByTypePayload,
    options?: UseQueryOptions<OperatorListAbsencesByTypeResponse, AxiosError, TData>
) => {
    return useQuery<OperatorListAbsencesByTypeResponse, AxiosError, TData>(
        operatorsKeys.listAbsencesByType(params),
        async () => await listAbsencesByType(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorPlanningAvailabilities = <TData = OperatorPlanningAvailabilitiesResponse>(
    params: OperatorPlanningAvailabilitiesPayload,
    options?: UseQueryOptions<OperatorPlanningAvailabilitiesResponse, AxiosError, TData>
) => {
    return useQuery<OperatorPlanningAvailabilitiesResponse, AxiosError, TData>(
        operatorsKeys.planningAvailabilities(params),
        async () => await planningAvailabilities(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorExport = <TData = undefined>(
    params: ExportOperatorPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        operatorsKeys.exportOperator(params),
        async () => await exportOperator(params),
        options
    );
};

export const useExportOperators = <TData = undefined>(options?: UseQueryOptions<undefined, AxiosError, TData>) => {
    return useQuery<undefined, AxiosError, TData>(operatorsKeys.export(), async () => await exportOperators(), options);
};

export const useGenerateNewOperatorPassword = (
    options?: UseMutationOptions<Operator, AxiosError, OperatorIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Operator, AxiosError, OperatorIdPayload>(async (params) => await generateNewPassword(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(operatorsKeys.detail(variables));
            queryClient.invalidateQueries(operatorsKeys.lists());
        },
    });
};

export const useSendMobilicEmail = <TData = undefined>(
    params: OperatorIdPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        operatorsKeys.sendMobilicEmail(params),
        async () => await sendMobilicEmail(params),
        options
    );
};

export const useOperatorMobilicTokenUpdate = (
    options?: UseMutationOptions<Operator, AxiosError, OperatorUpdateMobilicTokenPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Operator, AxiosError, OperatorUpdateMobilicTokenPayload>(
        async (params) => await updateMobilicToken(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(operatorsKeys.detail(data.id));
                queryClient.invalidateQueries(operatorsKeys.lists());
            },
        }
    );
};

export const useExportOperatorsPlanning = <TData = undefined>(
    params: OperatorPlanningAvailabilitiesPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        operatorsKeys.exportOperatorsPLanning(params),
        async () => await exportOperatorsPlanning(params),
        options
    );
};
