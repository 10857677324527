// Types of the backend API
type ObjectId = string;

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    koala = 'koala',
    api = 'api',
    nothing = '',
}

export interface Organization {
    id: string;
    name: string;
    applications?: Application[];
    createdAt: string;
}

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum Permission {
    superAdmin = 'superAdmin',
    admin = 'admin',
    logAs = 'logAs',
    users = 'users',
    roles = 'roles',
    backoffice = 'backoffice',
    organizations = 'organizations',
    applications = 'applications',
    staticPages = 'staticPages',
    questions = 'questions',
    valueLists = 'valueLists',
    settings = 'settings',
    // IDL
    transporters = 'transporters',
    operators = 'operators',
    customers = 'customers',
    vehicles = 'vehicles',
    places = 'places',
    tasks = 'tasks',
    customerForecasts = 'customerForecasts',
    taskGroups = 'taskGroups',
    issues = 'issues',
    events = 'events',
    operatorAbsences = 'operatorAbsences',
    // UI permissions
    // - Tracking
    taskGroupTracking = 'taskGroupTracking',
    planningTracking = 'planningTracking',
    forecastTracking = 'forecastTracking',
    operatorTracking = 'operatorTracking',
    vehicleTracking = 'vehicleTracking',
    publicHolidays = 'publicHolidays',
    autoPlanning = 'autoPlanning',
    // -Prebilling
    prebilling = 'prebilling',
    customerPrebilling = 'customerPrebilling',
    transporterPrebilling = 'transporterPrebilling',
    // -Configuration
    placeConfiguration = 'placeConfiguration',
    customerConfiguration = 'customerConfiguration',
    transporterConfiguration = 'transporterConfiguration',
    operatorConfiguration = 'operatorConfiguration',
    vehicleConfiguration = 'vehicleConfiguration',
}

export enum RoleScope {
    organizations = 'organizations',
    users = 'users',
    groups = 'groups',
}

export enum GenericStatus {
    success = 'success',
    warning = 'warning',
    error = 'error',
}

export interface Scope {
    organizations: ObjectId[];
    users: ObjectId[];
}
export interface Role {
    id: ObjectId;
    name: string;
    slug: RoleSlug;
    isRemovable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}
export enum RoleSlug {
    superAdmin = 'superAdmin',
    owner = 'owner',
    admin = 'admin',
    backOfficeAdmin = 'backOfficeAdmin',
    superDispatcher = 'superDispatcher',
    dispatcher = 'dispatcher',
    vehicleTracker = 'vehicleTracker',
    user = 'user',
    operator = 'operator',
}

export interface UserScope {
    // [key in RoleScope]?: ObjectId[];
    [RoleScope.organizations]?: ObjectId[] | Organization[];
    [RoleScope.users]?: ObjectId[] | User[];
}
export interface User {
    id: ObjectId;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: UserScope;
    organization?: Organization;
    password?: string;
    places?: Place[];
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export enum AgendaJobStatus {
    finished = 'finished',
    failed = 'failed',
    scheduled = 'scheduled',
    running = 'running',
}
export interface AgendaJob {
    id: ObjectId;
    name: string;
    type: string;
    priority: number;
    failCount?: number;
    failReason?: string;
    failedAt?: string;
    nextRunAt?: string;
    lastModifiedBy?: string | null;
    lockedAt?: string | null;
    lastRunAt?: string | null;
    lastFinishedAt?: string;
    status?: AgendaJobStatus;
    data: {
        [key: string]: unknown;
    };
}

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    it = 'it',
    // es = 'es',
    // du = 'du',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export const LanguagesList: SupportedLanguage[] = [SupportedLanguage.fr, SupportedLanguage.en, SupportedLanguage.it];

export interface ApiInfos {
    name: string;
    version: string;
    env: 'development' | 'production' | 'preprod';
    instance: string;
    request: string;
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

export enum NotificationStatus {
    pending = 'pending', // not useful here
    sent = 'sent', // not useful here
    received = 'received',
    opened = 'opened',
    failed = 'failed', // not useful here
}

export interface Notification {
    id: ObjectId;
    type: 'text';
    channel: 'pull';
    payload: {
        text: string;
        context?: {
            [key: string]: any;
        };
    };
    reference: string;
    status: NotificationStatus;
    sendAt: string;
}

export interface Coordinates {
    id?: ObjectId;
    latitude: number;
    longitude: number;
}
export enum AddressType {
    personal = 'personal',
    professional = 'professional',
    other = 'other',
}
export interface Address {
    id?: ObjectId;
    type?: AddressType;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}

export enum ValueListFieldValueType {
    weekday = 'weekday',
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    date = 'date',
}

export enum ValueListFieldUnit {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
}

export interface ValueListField {
    id: string;
    title: string;
    columnTitle?: string;
    isSortable?: boolean;
    sortKey?: string;
    name: string;
    valueType: ValueListFieldValueType;
    unit?: ValueListFieldUnit;
    minValue?: number;
    maxValue?: number;
    isEnabled: boolean;
    isRequired?: boolean;
}
export interface ValueList {
    id: string;
    title: string;
    slug: string;
    fields: ValueListField[];
    meta: {
        [key: string]: any;
    };
}
export interface ValueListItem {
    id: string;
    fields: {
        [key: string]: string | number;
    };
    meta: {
        [key: string]: any;
    };
}

export enum ValueListSlug {
    issueSubCategories = 'issue-subCategories',
    issueCategories = 'issue-categories',
    customerContracts = 'customer-contracts',
    customerServices = 'customer-services',
    vehicleBrands = 'vehicle-brands',
    vehicleModels = 'vehicle-models',
    vehicleTypes = 'vehicle-types',
    vehicleProperties = 'vehicle-properties',
    vehicleOwnershipTypes = 'vehicle-ownership-types',
    vehicleGasTypes = 'vehicle-gas-types',
    vehicleTransportTypes = 'vehicle-transport-types',
    transporterServices = 'transporter-services',
    transporterContracts = 'transporter-contracts',
    operatorContracts = 'operator-contracts',
    operatorAbsenceReasons = 'operator-absence-reasons',
    operatorProfiles = 'operator-profiles',
    operatorSlots = 'operator-slots',
    idlEntities = 'idl-entities',
    placeActivityTypes = 'place-activity-types',
    groups = 'groups',
    holidays = 'holidays',
    dockTypes = 'dock-types',
    roles = 'roles',
}

export enum TaskGroupStatus {
    readyToStart = 'readyToStart',
    toPlan = 'toPlan',
    inProgress = 'inProgress',
    done = 'done',
    toClose = 'toClose',
}
export enum TaskGroupPreBillingStatus {
    toPreBill = 'toPreBill',
    preBilled = 'preBilled',
}

export enum TaskGroupTransferStatus {
    interGroup = 'interGroup',
    internal = 'internal',
}

export enum TaskState {
    pending = 'pending',
}
export enum PlaceType {
    warehouse = 'warehouse',
    shop = 'shop',
}
export enum IssueCriticityLevel {
    low = 'low',
    medium = 'medium',
    high = 'high',
    critical = 'critical',
}
export enum IssueStatus {
    opened = 'opened',
    repairing = 'repairing',
    closed = 'closed',
}

export enum InvoicingUnit {
    service = 'service',
    vehicle = 'vehicle',
}
export enum CustomerType {
    standard = 'standard',
    scheduling = 'scheduling',
}
export interface RemoteFile {
    id: string;
    originalName: string;
    mimeType: string;
    url: string;
    thumbnailUrl?: string;
    name?: string;
}

export enum VehiclePlanningStatus {
    planned = 'planned',
    used = 'used',
    repairing = 'repairing',
}

export interface TaskGroup {
    id: ObjectId;
    reference: string;
    tasks: Task[];
    date: string;
    startDate?: string;
    endDate?: string;
    status: TaskGroupStatus;
    customer: Customer;
    startingPlace: Place;
    endingPlace?: Place;
    transferPlace?: Place;
    operator?: Operator; // Chauffeur
    shiftStartedAt?: string;
    shiftEndedAt?: string;
    wantedVehicleType?: string; // vehicleType
    vehicle?: Vehicle;
    preBillingStatus: TaskGroupPreBillingStatus;
    transferStatus: TaskGroupTransferStatus;
    transporterService?: TransporterService;
    orderNumber?: string;
    computedProperties?: {
        operatorPlaces?: Array<Place['id']>;
        operatorLoggedInWithQRCode?: boolean;
        endingPlaceIsDifferentFromStartingPlace?: boolean;
        taskCount: number;
        volume: number;
        weight: number;
        mileage: number;
        vehicleStartingMileage: number;
        vehicleEndingMileage: number;
        vehicleOpenedIssues?: number;
        vehicleOpenedIssuesDuringTaskGroup?: number;
        vehicleCarbonEmission: number;
        soldAmount: number;
        purchasedAmount: number;
        marginAmount: number;
        penaltyAmount: number;
        customerAdditionalServicesAmount: number;
        customerAdditionalServiceCount: number;
        transporterAdditionalServicesAmount: number;
        transporterAdditionalServiceCount: number;
        handlingUnit: number;
        vehiclePlanningStatus: VehiclePlanningStatus;
        transporterId?: ObjectId;
        prebillingNumber?: string;
    };
}

export interface Recipient {
    firstName: string;
    lastName: string;
}
export interface Task {
    id: ObjectId;
    reference: string;
    scheduledAt: Date;
    status: TaskState;
    address: Address;
    recipient: string;
}
export interface Contact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    jobTitle: string;
}
export interface DockType {
    type?: ValueListItem; // dockType
    count: number;
}
export interface Dock {
    name: string;
    allowedVehicleTypes: ValueListItem[]; // vehicleType
}

export enum WeekDay {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export type WeekDaysRecord<T> = {
    [day in WeekDay]: T;
};

export interface Place {
    id: ObjectId;
    reference: string;
    isActive: boolean;
    name: string;
    type: PlaceType;
    group?: ValueListItem; // group
    activity?: ValueListItem; // placeActivityType
    customers: Customer[];
    secondaryPlaces?: Place[];
    firstLoadingHour?: number;
    address: Address;
    managers: Contact[];
    openHours: WeekDaysRecord<{
        isEnabled: boolean;
        fromHour: number;
        toHour: number;
    }>;
    workingHolidays: ValueListItem[]; // holiday
    closedDays: Array<{
        fromDate: string;
        toDate: string;
        isFullDay?: boolean;
    }>;
    dockTypes: DockType[];
    docks: Dock[];
    hasMobilicAdminToken: boolean;
    adminMobilicToken?: string;
    mobilicCompanyId?: number;
}
export interface TransporterService {
    id: ObjectId;
    label: string;
    type?: ValueListItem; // transporterServiceType
    vehicleType?: ValueListItem; // vehicleType
    dailyCount: {
        minimum: number;
        maximum: number;
    };
    referencePeriod: {
        fromDate: string;
        toDate: string;
    };
    dailyCost: number;
    penaltyCost: number;
    tolerance: number;
    toleranceUnit: 'number' | 'percentage';
}
export interface Transporter {
    id: ObjectId;
    reference: string;
    passkey: string;
    name: string;
    companyName: string;
    isActive: boolean;
    groups: ValueListItem[]; // groups
    address: Address;
    billingAddress?: Address;
    primaryContact: Contact;
    administrativeContact: Contact;
    services: TransporterService[];
    openHours: WeekDaysRecord<{
        isEnabled: boolean;
        fromHour: number;
        toHour: number;
    }>;
    workingHolidays: ValueListItem[]; // holiday
    exceptionnalDays: Array<{
        isOpened: boolean;
        fromDate: string;
        toDate: string;
    }>;
    meta: {
        creationStep?: number;
        isSameAddressForBilling?: boolean;
        [key: string]: unknown;
    };
}
export interface VehicleSize {
    width: number;
    height: number;
    length: number;
}
export interface Vehicle {
    id: ObjectId;
    reference: string;
    plateNumber: string;
    isActive: boolean;
    transporter?: Transporter;
    ownershipType: ValueListItem; // vehicleOwnershipType
    type: ValueListItem; // vehicleType
    brand: ValueListItem; // vehicleBrand
    model: ValueListItem; // vehicleModel
    groups: ValueListItem[]; // group
    mileage?: number;
    usableSize: VehicleSize;
    size: VehicleSize;
    gasType?: ValueListItem; // vehiculeGasType
    carbonEmission: number;
    transportType?: ValueListItem; // vehicleTransportType
    dailyCost: number;
    meta: {
        [key: string /* ValueListItem - vehicleOtherProperty */]: unknown;
    };
    computedProperties: {
        issueStatus: IssueStatus;
        lastTaskAt: Date;
        lastAddress: string;
        lastCoordinates: Coordinates;
    };
    organization?: Organization;
    available: boolean;
}
export interface Issue {
    id: ObjectId;
    date: string;
    description: string;
    operator?: Operator;
    taskGroup?: TaskGroup;
    criticityLevel: IssueCriticityLevel;
    status: IssueStatus;
    category?: ValueListItem; // issueCategories
    subCategory?: ValueListItem; // issueSubCategories
    remoteFiles: RemoteFile[];
    comments: IssueComment[];
    createdAt: string;
    updatedAt: string;
    closedAt: string;
}
export interface IssueComment {
    id: ObjectId;
    createdAt: string;
    user: User;
    text: string;
    type: IssueCommentType;
}

export enum IssueCommentType {
    repairing = 'repairing',
    closing = 'closing',
    adding = 'adding',
}
export interface Operator {
    id: ObjectId;
    reference: string;
    passkey: string;
    firstName: string;
    lastName: string;
    mobilicEmail: string;
    phone: string;
    profile?: ValueListItem; // operatorProfiles
    contractType?: ValueListItem; // operatorContracts
    contractStartedAt: string;
    preferedSlots: ValueListItem[]; // operatorSlots
    groups: ValueListItem[]; // groups
    transporter: Transporter;
    place: Place;
    attachedTo?: ValueListItem; // idlEntities
    account?: User;
    available: boolean;
    computedProperties: {
        lastWorkingDayStart: string;
        lastWorkingDayEnd?: string;
        timeWorkedOverSemester: number;
        overtimeOverSemester: number;
    };
    isActive: boolean;
}

export enum DaySlots {
    morning = 'Matin',
    evening = 'Après-midi',
}

export interface OperatorAbsence {
    id: ObjectId;
    operator: Operator;
    startDate: string;
    endDate: string;
    type: ValueListItem; // absenceType
    slot: ValueListItem;
    operatorGroups: Array<ValueListItem['id']>;
    status: OperatorAbsenceStatus;
    duration: number;
    preferredSlot: DaySlots;
    computedProperties: {
        type?: string;
        commonId: string;
    };
}

export enum OperatorAbsenceStatus {
    coming = 'coming',
    inProgress = 'inProgress',
    past = 'past',
}

export interface OperatorAbsenceByType {
    type?: ValueListItem; // absenceType
    days: number;
}
export interface OperatorShift {
    id: ObjectId;
    operator: Operator;
    startDate: string;
    endDate?: string;
    workedTime?: number;
    status: OperatorShiftStatus;
    computedProperties: {
        operatorPlaces: Array<Place['id']>;
    };
}

export interface OperatorPlanning {
    id: Operator['id'];
    operator: Pick<Operator, 'id' | 'firstName' | 'lastName'> & {
        overtimeOverSemester: number | null; // minutes
        profileWorkDuration?: number; // hours
        timeWorkedOverSemester: number | null; // minutes
    };
    shifts: OperatorShift[];
    absences: OperatorAbsence[];
    taskGroups: Array<TaskGroup & { customerName?: string }>;
}

export enum OperatorShiftStatus {
    worked = 'worked',
    planned = 'planned',
    holiday = 'holiday', // repos
    unworked = 'unworked', // non travavillé
    unavailable = 'unavailable',
    finished = 'finished',
    inProgress = 'inProgress',
}

export enum VehicleShiftStatus {
    planned = 'planned',
    used = 'used',
    repairing = 'repairing',
}

export interface OperatorAvailability {
    date: string;
    availableOperators: number;
}
export interface VehicleAvailability {
    date: string;
    availableVehicles: number;
}

export interface InvoicingPrice {
    invoicingUnit: InvoicingUnit;
    vehicleType?: ValueListItem; // vehicleType
    serviceType?: ValueListItem; // serviceType
    standard: number;
    weekend: number;
    holiday: number;
    reuse: number;
    fromDate: string;
    toDate: string;
}
export interface Customer {
    id: ObjectId;
    reference: string;
    type?: CustomerType;
    name: string;
    isActive: boolean;
    address: Address;
    workingHours?: {
        minimum: number;
        maximum: number;
    };
    pauseDuration: {
        unit?: PauseDurationUnit;
        value?: number;
    };
    primaryContact?: Contact;
    administrativeContact: Contact;
    invoicingUnit?: InvoicingUnit;
    invoicingPrices: InvoicingPrice[];
    forecastStatus: GenericStatus;
    organization?: Organization;
    places: Place[];
    computedProperties?: {
        groups?: ObjectId[];
    };
    meta: {
        [key: string]: unknown;
        creationStep?: number;
    };
}

export enum PauseDurationUnit {
    min = 'min',
    h = 'h',
}

export interface CustomerForecast {
    id: ObjectId;
    customer: Customer;
    date: string;
    capacity: number;
    tolerance?: number;
    actualCount?: number;
}

export enum InvoicingKeys {
    standard = 'standard',
    weekend = 'weekend',
    holiday = 'holiday',
    reuse = 'reuse',
}

export interface GeoJson {
    type: string;
    coordinates: Array<[number, number]>;
}

export interface PublicHolidays {
    [key: Customer['id']]: string[];
}

export enum TaskGroupServiceType {
    transporter = 'transporter',
    customer = 'customer',
}

export interface TaskGroupService {
    id: ObjectId;
    label: string;
    amount: number;
    taskGroup: TaskGroup;
    type: TaskGroupServiceType;
}

export interface PreBillingCustomer {
    id: ObjectId;
    customerName: string;
    purchased: number;
    margin: number;
    sold: number;
    additionalServices: number;
    additionalServiceCount: number;
    mileage: number;
    taskCount: number;
    taskGroupCount: number;
    handlingUnit: number;
}

export interface PreBillingTransporter {
    id: ObjectId;
    transporterName: string;
    purchased: number;
    penaltyAmount: number;
    mileage: number;
    taskCount: number;
    taskGroupCount: number;
    handlingUnit: number;
    marginAmount: number;
}
export interface OperatorCountByGroup {
    id: ObjectId;
    groupName: string;
    groupReference: string;
    totalCount: number;
}

export interface OperatorCountByTransporter {
    id: Transporter['id'];
    transporterName: string;
    totalCount: number;
}

export interface VehiclePlanning {
    id: string;
    reference: string;
    taskGroups: Array<
        Pick<TaskGroup, 'date' | 'startDate' | 'status' | 'reference' | 'id' | 'endDate'> & { customerName?: string }
    >;
    ownershipType?: OwnershipType;
    mileage?: number;
    transporter?: Pick<Transporter, 'name' | 'id'>;
    issues: Array<Pick<Issue, 'id' | 'date' | 'closedAt' | 'status'>>;
}

export interface OwnershipType {
    vehicleOwnershipTypeName: string;
    vehicleOwnershipTypeReference: string;
    id: string;
}

export interface TaskGroupAssignmentProposal {
    id: ObjectId;
    taskGroup: TaskGroup;
    operator?: Operator;
    vehicle?: Vehicle;
}
