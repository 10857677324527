import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    VehicleCreatePayload,
    VehicleIdPayload,
    VehicleListPayload,
    VehicleListResponse,
    VehicleUpdatePayload,
    remove,
    update,
    VehicleListTaskGroupsResponse,
    VehicleListTaskGroupsPayload,
    listTaskGroups,
    VehicleListIssuesResponse,
    VehicleListIssuesPayload,
    listIssues,
    exportVehicle,
    exportVehicles,
    ExportVehiclePayload,
    createIssue,
    VehicleIssueCreatePayloadFormData,
    exportVehiclesPlanning,
} from './api/vehicles';
import { Issue, Vehicle } from './api/types';
import { VehiclePlanningAvailabilitiesPayload } from './api/taskGroups';

export const vehiclesKeys = {
    all: ['vehicles'],
    lists: () => [...vehiclesKeys.all, 'list'],
    list: (params?: VehicleListPayload) => [...vehiclesKeys.lists(), params],
    details: () => [...vehiclesKeys.all, 'details'],
    detail: (id?: VehicleIdPayload) => [...vehiclesKeys.details(), id],
    listTaskGroups: (params?: VehicleListTaskGroupsPayload) => [...vehiclesKeys.all, 'listTaskGroups', params],
    listIssuesAll: () => [...vehiclesKeys.all, 'listIssues'],
    listIssues: (params?: VehicleListIssuesPayload) => [...vehiclesKeys.listIssuesAll(), params],
    export: () => [...vehiclesKeys.all, 'export'],
    exportVehicle: (vehicleId: ExportVehiclePayload) => [...vehiclesKeys.export(), 'vehicles', vehicleId],
    exportVehiclePlanning: (params: VehiclePlanningAvailabilitiesPayload) => [
        ...vehiclesKeys.all,
        'exportPlanning',
        params,
    ],
};

export const useVehicleList = <TData = VehicleListResponse>(
    params?: VehicleListPayload,
    options?: UseQueryOptions<VehicleListResponse, AxiosError, TData>
) => {
    return useQuery<VehicleListResponse, AxiosError, TData>(vehiclesKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useVehicleDetails = <TData = Vehicle>(
    id?: VehicleIdPayload,
    options?: UseQueryOptions<Vehicle, AxiosError, TData>
) => {
    return useQuery<Vehicle, AxiosError, TData>(vehiclesKeys.detail(id), async () => await details(id), options);
};

export const useVehicleCreate = (options?: UseMutationOptions<Vehicle, AxiosError, VehicleCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Vehicle, AxiosError, VehicleCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(vehiclesKeys.lists());
        },
    });
};

export const useVehicleIssueCreate = (
    options?: UseMutationOptions<Issue, AxiosError, VehicleIssueCreatePayloadFormData>
) => {
    const queryClient = useQueryClient();

    return useMutation<Issue, AxiosError, VehicleIssueCreatePayloadFormData>(
        async (params) => await createIssue(params),
        {
            ...options,
            onSuccess: (...args) => {
                options?.onSuccess?.(...args);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(vehiclesKeys.lists());
            },
        }
    );
};

export const useVehicleUpdate = (options?: UseMutationOptions<Vehicle, AxiosError, VehicleUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Vehicle, AxiosError, VehicleUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(vehiclesKeys.detail(variables.id));
        },
    });
};

export const useVehicleRemove = (options?: UseMutationOptions<undefined, AxiosError, VehicleIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, VehicleIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(vehiclesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(vehiclesKeys.lists());
        },
    });
};

export const useVehicleListTaskGroups = <TData = VehicleListTaskGroupsResponse>(
    params: VehicleListTaskGroupsPayload,
    options?: UseQueryOptions<VehicleListTaskGroupsResponse, AxiosError, TData>
) => {
    return useQuery<VehicleListTaskGroupsResponse, AxiosError, TData>(
        vehiclesKeys.listTaskGroups(params),
        async () => await listTaskGroups(params),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};

export const useVehicleListIssues = <TData = VehicleListIssuesResponse>(
    params: VehicleListIssuesPayload,
    options?: UseQueryOptions<VehicleListIssuesResponse, AxiosError, TData>
) => {
    return useQuery<VehicleListIssuesResponse, AxiosError, TData>(
        vehiclesKeys.listIssues(params),
        async () => await listIssues(params),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};

export const useVehicleExport = <TData = undefined>(
    params: ExportVehiclePayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        vehiclesKeys.exportVehicle(params),
        async () => await exportVehicle(params),
        options
    );
};

export const useExportVehicles = <TData = undefined>(options?: UseQueryOptions<undefined, AxiosError, TData>) => {
    return useQuery<undefined, AxiosError, TData>(vehiclesKeys.export(), async () => await exportVehicles(), options);
};

export const useExportVehiclesPlanning = <TData = undefined>(
    params: VehiclePlanningAvailabilitiesPayload,
    options?: UseQueryOptions<undefined, AxiosError, TData>
) => {
    return useQuery<undefined, AxiosError, TData>(
        vehiclesKeys.exportVehiclePlanning(params),
        async () => await exportVehiclesPlanning(params),
        options
    );
};
