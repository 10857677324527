import { defineMessages } from 'react-intl';

import { OperatorShiftStatus, VehicleShiftStatus } from '../queries/api/types';

const planningsMessages = defineMessages({
    planned: {
        id: 'plannings.planned',
        defaultMessage: 'Planifié',
        description: 'Planned',
    },
    worked: {
        id: 'plannings.worked',
        defaultMessage: 'Travaillé',
        description: 'Worked',
    },
    holiday: {
        id: 'plannings.holiday',
        defaultMessage: 'Repos',
        description: 'Holiday',
    },
    unworked: {
        id: 'plannings.unworked',
        defaultMessage: 'Non travaillé',
        description: 'Unworked',
    },
    unavailable: {
        id: 'plannings.unavailable',
        defaultMessage: 'Indisponible',
        description: 'Unavailable',
    },
    used: {
        id: 'plannings.used',
        defaultMessage: 'Utilisé',
        description: 'Used',
    },
    repairing: {
        id: 'plannings.repairing',
        defaultMessage: 'En réparation',
        description: 'Repairing',
    },
    finished: {
        id: 'plannings.finished',
        defaultMessage: 'Terminé',
        description: 'Finished',
    },
    inProgress: {
        id: 'plannings.inProgress',
        defaultMessage: 'En cours',
        description: 'In progress',
    },
    accumulatedHours: {
        id: 'plannings.acummulated_hours',
        defaultMessage: 'Cumul heures semestre / contrat',
    },
    availabilitiesTooltip: {
        id: 'plannings.availabilities_tooltip',
        defaultMessage:
            'Nb de {ressource, select, operators {chauffeurs} vehicles {vehicules} other {ressources}} disponibles par jour',
        description: 'Available ressources tooltip',
    },
    addAbsenceSuccess: {
        id: 'plannings.add_absence_success',
        defaultMessage: 'Indisponibilité saisie avec succès',
        description: 'Add absence success',
    },
    exportDriversPlanning: {
        id: 'plannings.export.drivers_planning',
        defaultMessage: 'Exporter le planning chauffeurs',
    },
    exportVehiclesPlanning: {
        id: 'plannings.export.vehicles_planning',
        defaultMessage: 'Exporter le planning véhicules',
    },
});

export const planningsCaptionOperatorMessagesMap = new Map([
    [OperatorShiftStatus.planned, planningsMessages.planned],
    [OperatorShiftStatus.worked, planningsMessages.worked],
    [OperatorShiftStatus.holiday, planningsMessages.holiday],
    [OperatorShiftStatus.unworked, planningsMessages.unworked],
    [OperatorShiftStatus.unavailable, planningsMessages.unavailable],
    [OperatorShiftStatus.finished, planningsMessages.finished],
    [OperatorShiftStatus.inProgress, planningsMessages.inProgress],
]);

export const planningsCaptionVehicleMessagesMap = new Map([
    [VehicleShiftStatus.planned, planningsMessages.planned],
    [VehicleShiftStatus.used, planningsMessages.used],
    [VehicleShiftStatus.repairing, planningsMessages.repairing],
]);

export default planningsMessages;
