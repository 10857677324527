import { Button, DrawerProps, Form, Modal, Skeleton, Typography } from 'antd';
import NiceModal, { antdDrawer, create, useModal } from '@ebay/nice-modal-react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { HiNoSymbol, HiOutlineArrowsRightLeft, HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { HiOutlineCalendar, HiX } from 'react-icons/hi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import {
    Permission,
    PermissionRight,
    TaskGroup,
    TaskGroupStatus,
    TaskGroupTransferStatus,
    TransporterService,
} from '../../../queries/api/types';
import {
    useManuallycloseTaskGroup,
    useTaskGroupClose,
    useTaskGroupDetails,
    useTaskGroupListAvailableOperators,
    useTaskGroupListAvailableVehicles,
    useTaskGroupUnplan,
    useTaskGroupUpdate,
} from '../../../queries/taskGroups';
import ApiResult from '../../../components/ApiResult';
import TaskGroupStatusTag from './TaskGroupStatusTag';
import CustomDrawer from '../../../components/CustomDrawer';
import TaskGroupDetailsDescriptions from './TaskGroupDetailsDescriptions';
import TaskGroupDetailsTabsCard from './TaskGroupDetailsTabsCard';
import { errorMessage, successMessage } from '../../../helpers/message';
import genericMessages from '../../../i18n/genericMessages';
import formMessages from '../../../i18n/formMessages';
import taskGroupMessages from '../../../i18n/taskGroupMessages';
import TaskGroupVehicleSelector from './TaskGroupVehicleSelector';
import TaskGroupOperatorSelector from './TaskGroupOperatorSelector';
import showTaskGroupPlanificationModal from './TaskGroupPlanificationModal';
import showOperatorUnavailableModal from './OperatorUnavailableModal';
import showVehicleUnavailableModal from './VehicleUnavailableModal';
import { useVehicleDetails } from '../../../queries/vehicles';
import TaskGroupTransporterServiceModal from './TaskGroupTransporterServiceModal';
import { useAuth } from '../../../context/AuthContext';
import { hasPermission } from '../../../helpers/security';
import { Warning } from '../../../components/icons';
import TransferTaskGroupModal from './TransferTaskGroupModal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { checkMobilicMission, TaskgroupCheckTaskGroupMobilicMissionResponse } from '../../../queries/api/taskGroups';
import ErrorModal from '../../../components/ErrorModal';

const closeCurrentTaskGroupsModalId = 'close-currentTaskGroup-modal';
const confirmCloseTaskGroupModalId = 'confirm-closeTaskGroup-modal';
const confirmCloseMissionEndedTaskGroupModalId = 'confirm-closeMissionEndedTaskGroup-modal';

type Props = DrawerProps &
    Record<string, any> & {
        taskGroupId?: TaskGroup['id'];
        onUpdateSuccess?: () => void;
    };

const taskGroupTransporterServiceModalId = 'taskGroup-transporter-service-modal';

const TaskGroupDetailsDrawer = create<Props>(({ taskGroupId, onUpdateSuccess, ...props }) => {
    const { user } = useAuth();
    const [form] = Form.useForm<{ vehicleId: string; operatorId: string; transporterServiceIndex: string }>();
    const { data: vehicles } = useTaskGroupListAvailableVehicles({ id: taskGroupId, pageSize: 999 });
    const { formatMessage } = useIntl();
    const modal = useModal();
    const { mutate: updateTaskGroup } = useTaskGroupUpdate();
    const { mutate: closeTaskGroup } = useTaskGroupClose();
    const transferTaskGroupModal = useModal(TransferTaskGroupModal);
    const closeCurrentTaskGroupsModal = useModal(closeCurrentTaskGroupsModalId);
    const confirmCloseTaskGroupModal = useModal(confirmCloseTaskGroupModalId);
    const confirmCloseMissionEndedTaskGroupModal = useModal(confirmCloseMissionEndedTaskGroupModalId);
    const {
        data: taskGroup,
        isLoading,
        isError,
        error,
        isSuccess,
    } = useTaskGroupDetails(taskGroupId, { enabled: !!taskGroupId });
    const { mutateAsync: unplanTaskGroup } = useTaskGroupUnplan();
    const { mutateAsync: manuallyCloseTaskGroup } = useManuallycloseTaskGroup();
    const hideModal = useCallback(async () => await modal.hide(), [modal]);
    const isTaskGroupReadyToStart = taskGroup?.status === TaskGroupStatus.readyToStart;
    const isTaskGroupToPlan = taskGroup?.status === TaskGroupStatus.toPlan;
    const isTaskGroupToClose = taskGroup?.status === TaskGroupStatus.toClose;
    const showTaskGroupAsTransfered =
        taskGroup?.transferStatus === TaskGroupTransferStatus.interGroup &&
        !user?.places?.map((place) => place.id).includes(taskGroup.startingPlace.id);
    const taskGroupToClose = taskGroup?.status === TaskGroupStatus.toClose;
    const canPlanTaskGroup = hasPermission(user, Permission.taskGroupTracking, PermissionRight.write);
    const userIsAdmin = hasPermission(user, Permission.admin);
    const isExternalTaskGroup = !!taskGroup?.vehicle?.transporter?.id;

    const hasVehicleOrOperator = !!taskGroup?.vehicle || !!taskGroup?.operator;
    const onClickUnplan = useCallback(() => {
        Modal.confirm({
            width: 480,
            centered: true,
            title: formatMessage({
                id: 'task_group.details_drawer.unplan.modal.confirm_title',
                defaultMessage: 'Déplanification de l’OT',
            }),
            content: formatMessage({
                id: 'task_group.details_drawer.unplan.modal.confirm_text',
                defaultMessage: 'Êtes-vous sur de vouloir déplanifier cet OT ?',
            }),
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            okText: formatMessage(formMessages.validate),
            onOk: async () =>
                await unplanTaskGroup(taskGroupId!, {
                    onSuccess: () => {
                        successMessage({
                            content: formatMessage({
                                id: 'task_group.details_drawer.unplan.success_message',
                                defaultMessage: 'OT déplanifié avec succès',
                            }),
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error?.response?.status,
                            }),
                        });
                    },
                }),
        });
    }, [formatMessage, taskGroupId, unplanTaskGroup]);

    const vehicleId = Form.useWatch('vehicleId', form);
    const operatorId = Form.useWatch('operatorId', form);
    const [transporterService, setTransporterService] = useState<TransporterService | undefined>();

    const [externalOperator, setExternalOperator] = useState<boolean>(false);
    const [taskGroupMobilicMission, setTaskGroupMobilicMission] = useState<
        TaskgroupCheckTaskGroupMobilicMissionResponse | undefined
    >(undefined);
    const { data: vehicleDetails } = useVehicleDetails(vehicleId, { enabled: !!vehicleId });
    const { data: availableVehicles } = useTaskGroupListAvailableVehicles({
        id: taskGroupId,
        pageSize: 999,
    });
    const { data: availableOperators } = useTaskGroupListAvailableOperators(
        {
            id: taskGroupId,
            pageSize: 999,
        },
        { enabled: !!vehicleDetails && !vehicleDetails?.transporter }
    );
    const planificationHandler = () => {
        if (taskGroupId && vehicleId) {
            showTaskGroupPlanificationModal(async () => {
                updateTaskGroup(
                    {
                        id: taskGroupId,
                        vehicle: vehicleId,
                        operator: operatorId,
                        transporterService: transporterService
                            ? {
                                  ...transporterService,
                                  type: transporterService.type?.id,
                                  vehicleType: transporterService.vehicleType?.id,
                              }
                            : undefined,
                    },
                    {
                        onSuccess: () => {
                            successMessage({
                                content: formatMessage(taskGroupMessages.planificationSuccess),
                            });
                            form.setFieldsValue({ vehicleId: undefined, operatorId: undefined });
                            onUpdateSuccess?.();
                            hideModal();
                        },
                        onError: (error) => {
                            const { vehicleAvailable, operatorAvailable } = error.response?.data.fields;
                            if (vehicleAvailable === 'false') {
                                showVehicleUnavailableModal();
                                return;
                            }
                            if (operatorAvailable === 'false') {
                                showOperatorUnavailableModal();
                            }
                        },
                    }
                );
            });
        }
    };
    const vehicle = useMemo(() => {
        return vehicles?.items.find((vehicle) => vehicle.id === vehicleId);
    }, [vehicleId, vehicles?.items]);
    const transporter = vehicle?.transporter;

    useEffect(() => {
        form.setFieldsValue({ transporterServiceIndex: undefined });
    }, [form, vehicleId]);

    useEffect(() => {
        if (transporter) {
            setExternalOperator(true);
        } else {
            setExternalOperator(false);
        }
    }, [transporter]);

    useEffect(() => {
        if (!vehicleId) {
            form.setFieldsValue({ operatorId: undefined, transporterServiceIndex: undefined });
        } else if (transporter && transporterService === undefined) {
            form.setFieldsValue({ operatorId: undefined });
            NiceModal.show(taskGroupTransporterServiceModalId);
        }
    }, [form, taskGroup, taskGroupId, transporter, vehicle, vehicleId, transporterService]);

    const transferHandler = () => {
        transferTaskGroupModal
            .show({ taskGroupId: taskGroup?.id, places: taskGroup?.startingPlace.secondaryPlaces })
            .then(() => {
                onUpdateSuccess?.();
            });
    };

    const onClose = () => {
        closeCurrentTaskGroupsModal.show().then(() => {
            if (taskGroupId) {
                manuallyCloseTaskGroup({
                    shiftEndedAt: dayjs().toISOString(),
                    forAll: false,
                    taskGroups: [{ id: taskGroupId }],
                }).then(() => {
                    onUpdateSuccess?.();
                    closeCurrentTaskGroupsModal.hide();
                    modal.hide();
                });
            }
        });
    };

    const handleClose = () => {
        checkMobilicMission(taskGroupId).then((mission) => {
            if (mission.endTime) {
                setTaskGroupMobilicMission(mission);
                confirmCloseMissionEndedTaskGroupModal.show().then(() => {
                    if (taskGroupId) {
                        closeTaskGroup(taskGroupId, {
                            onSuccess: () => {
                                confirmCloseMissionEndedTaskGroupModal.hide();
                                successMessage({
                                    content: formatMessage({
                                        id: 'details_form_card.update.success_message',
                                        defaultMessage: 'Modifications enregistrées avec succès',
                                    }),
                                });
                            },
                            onError: (error) => {
                                confirmCloseMissionEndedTaskGroupModal.hide();
                                errorMessage({
                                    content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                        status: error?.response?.status,
                                    }),
                                });
                            },
                        });
                    }
                });
            } else {
                setTaskGroupMobilicMission(undefined);
                confirmCloseTaskGroupModal.show();
            }
        });
    };

    return (
        <CustomDrawer
            width={760}
            title={formatMessage({
                id: 'task_group.details_drawer.title',
                defaultMessage: "Détail de l'OT",
                description: 'Drawer title',
            })}
            {...props}
            {...antdDrawer(modal)}
        >
            {isLoading && <Skeleton paragraph={{ rows: 8 }} active loading title />}
            {!isLoading && isError && <ApiResult status={error?.response?.status} />}
            {!isLoading && isSuccess && (
                <>
                    <TaskGroupStatusTag taskGroup={taskGroup} className="mb-4" />
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <Typography.Title level={5} className="text-blue font-bold mb-0">
                                    <FormattedMessage
                                        id="task_group.details_drawer.task_group_reference"
                                        defaultMessage="OT n°{reference}"
                                        values={{ reference: taskGroup.reference }}
                                    />
                                </Typography.Title>
                                <Typography.Text type="secondary" className="flex items-center space-x-1">
                                    <HiOutlineCalendar className="text-base" />{' '}
                                    <span className="font-medium">
                                        <FormattedDate value={taskGroup.date} />
                                    </span>
                                </Typography.Text>
                            </div>
                            {isExternalTaskGroup && taskGroup.status === TaskGroupStatus.inProgress && (
                                <div className="inline-flex items-center">
                                    <Button
                                        className="text-blue inline-flex"
                                        icon={<HiOutlineClipboardDocumentCheck className="mr-2" size={20} />}
                                        onClick={onClose}
                                    >
                                        <FormattedMessage
                                            id="task_group.details_drawer.close_button"
                                            defaultMessage="Clôturer"
                                            tagName="span"
                                        />
                                    </Button>
                                </div>
                            )}
                            {taskGroupToClose && canPlanTaskGroup && (
                                <div className="text-red">
                                    <Warning className="mr-1" />
                                    <FormattedMessage
                                        id="task_group.details_drawer.taskGroup_toClose"
                                        defaultMessage="Tour à clôturer par le chauffeur dans son application"
                                    />
                                </div>
                            )}
                            {showTaskGroupAsTransfered && (
                                <Typography.Text className="text-grey-7 text-base font-medium">
                                    <HiOutlineArrowsRightLeft className="text-base mr-1" />
                                    <FormattedMessage
                                        id="task_group.details_drawer.transferred_to"
                                        defaultMessage="Transféré à l’entrepôt : {placeName}"
                                        values={{
                                            placeName:
                                                taskGroup.startingPlace?.name || taskGroup.startingPlace?.reference,
                                        }}
                                        tagName="span"
                                    />
                                </Typography.Text>
                            )}
                            {(userIsAdmin || !showTaskGroupAsTransfered) &&
                                isTaskGroupReadyToStart &&
                                canPlanTaskGroup && (
                                    <Button
                                        type="primary"
                                        icon={
                                            <span className="anticon">
                                                <HiNoSymbol className="text-base" />
                                            </span>
                                        }
                                        onClick={onClickUnplan}
                                        ghost
                                    >
                                        <FormattedMessage
                                            id="task_group.details_drawer.unplan_button"
                                            defaultMessage="Déplanifier"
                                            tagName="span"
                                        />
                                    </Button>
                                )}
                            {(userIsAdmin || !showTaskGroupAsTransfered) && isTaskGroupToPlan && canPlanTaskGroup && (
                                <Button
                                    type="primary"
                                    icon={
                                        <span className="anticon">
                                            <HiOutlineArrowsRightLeft className="text-base" />
                                        </span>
                                    }
                                    disabled={hasVehicleOrOperator}
                                    ghost
                                    onClick={transferHandler}
                                >
                                    <FormattedMessage
                                        id="task_group.details_drawer.transfer_button"
                                        defaultMessage="Transférer"
                                        tagName="span"
                                    />
                                </Button>
                            )}
                            {userIsAdmin && isTaskGroupToClose && (
                                <Button
                                    onClick={handleClose}
                                    className="text-blue inline-flex"
                                    icon={<HiX className="mr-2" size={20} />}
                                >
                                    <FormattedMessage
                                        id="task_group.details_drawer.close_button"
                                        defaultMessage="Clôturer"
                                        tagName="span"
                                    />
                                </Button>
                            )}
                        </div>
                        {(userIsAdmin || !showTaskGroupAsTransfered) && isTaskGroupToPlan && canPlanTaskGroup && (
                            <div className="p-6 bg-blueish">
                                <Typography.Paragraph className="text-orange font-bold text-lg">
                                    <FormattedMessage
                                        id="task_group.details_drawer.planning"
                                        defaultMessage="Planification de l'OT :"
                                    />
                                </Typography.Paragraph>
                                <Form.Provider
                                    onFormFinish={(name, { values, forms }) => {
                                        if (name === 'transporterServiceForm') {
                                            const { taskGroupPlanificationForm } = forms;
                                            taskGroupPlanificationForm.setFieldsValue(values);
                                        }
                                    }}
                                >
                                    <Form name="taskGroupPlanificationForm" form={form} onFinish={planificationHandler}>
                                        <div className="flex gap-3">
                                            <Form.Item className="w-1/2" name="vehicleId">
                                                <TaskGroupVehicleSelector
                                                    vehicles={availableVehicles}
                                                    placement="bottom"
                                                    vehicleId={vehicleId}
                                                    displayText={true}
                                                />
                                            </Form.Item>
                                            <Form.Item className="w-1/2" name="operatorId">
                                                <TaskGroupOperatorSelector
                                                    operators={availableOperators}
                                                    placement="bottom"
                                                    operatorId={operatorId}
                                                    externalOperator={externalOperator}
                                                />
                                            </Form.Item>
                                        </div>
                                        <Form.Item className="w-1/2" name="transporterServiceIndex" hidden>
                                            <></>
                                        </Form.Item>

                                        <Button
                                            htmlType="submit"
                                            block
                                            type="primary"
                                            onClick={planificationHandler}
                                            disabled={externalOperator ? !vehicleId : !vehicleId || !operatorId}
                                        >
                                            {formatMessage(taskGroupMessages.confirmPlanification)}
                                        </Button>
                                    </Form>
                                    <TaskGroupTransporterServiceModal
                                        id={taskGroupTransporterServiceModalId}
                                        taskGroupId={taskGroupId}
                                        transporterId={transporter?.id}
                                        vehicle={vehicle}
                                        onConfirm={(transporterService) => {
                                            setTransporterService(transporterService);
                                            NiceModal.hide(taskGroupTransporterServiceModalId);
                                        }}
                                        onCancel={() => {
                                            form.setFieldsValue({
                                                vehicleId: undefined,
                                                transporterServiceIndex: undefined,
                                            });
                                        }}
                                    />
                                </Form.Provider>
                            </div>
                        )}
                        <div className="space-y-4">
                            <TaskGroupDetailsDescriptions taskGroupId={taskGroupId} />
                        </div>
                        <TaskGroupDetailsTabsCard taskGroupId={taskGroupId} hideModal={hideModal} />
                    </div>
                </>
            )}
            <ConfirmationModal
                id={closeCurrentTaskGroupsModalId}
                title={formatMessage({ id: 'close_current_taskGroup_modal_title', defaultMessage: "Clôture de l'OT" })}
                text={formatMessage({
                    id: 'close_current_taskGroup_modal_content',
                    defaultMessage: 'Êtes-vous sur de vouloir clôturer cet OT ?',
                })}
            />
            <ErrorModal
                id={confirmCloseTaskGroupModalId}
                title={formatMessage({
                    id: 'confirm_close_taskGroup_modal_title',
                    defaultMessage: 'Clôturer la tournée',
                })}
                cancelText={formatMessage(genericMessages.close)}
                text={
                    <>
                        <p>
                            {formatMessage({
                                id: 'confirm_close_taskGroup_modal_body1',
                                defaultMessage: "Mobilic nous indique que la tournée n'a pas été clôturée.",
                            })}
                        </p>
                        <p className="text-orange text-semibold font-semibold">
                            {formatMessage({
                                id: 'confirm_close_taskGroup_modal_body2',
                                defaultMessage:
                                    'Il est nécessaire de contacter le chauffeur afin de lui demander de clôturer la tournée sur notre application ou sur Mobilic.',
                            })}
                        </p>
                    </>
                }
            />
            <ConfirmationModal
                id={confirmCloseMissionEndedTaskGroupModalId}
                title={formatMessage({
                    id: 'confirm_close_taskGroup_modal_title',
                    defaultMessage: 'Clôturer la tournée',
                })}
                cancelText={formatMessage(genericMessages.close)}
                text={
                    <>
                        {taskGroupMobilicMission && (
                            <p>
                                {formatMessage(
                                    {
                                        id: 'confirm_close_mission_ended_taskgroup_modal_body1',
                                        defaultMessage:
                                            'La tournée a été clôturée sur Mobilic le {day} à {hour}. Confirmez vous ces informations ?',
                                    },
                                    {
                                        day: dayjs.unix(taskGroupMobilicMission.endTime).format('DD/MM/YYYY'),
                                        hour: dayjs.unix(taskGroupMobilicMission.endTime).format('HH:mm'),
                                    }
                                )}
                            </p>
                        )}
                        <p className="text-orange text-semibold font-semibold">
                            {formatMessage({
                                id: 'confirm_close_mission_ended_taskgroup_modal_body2',
                                defaultMessage: 'Pour toutes modifications veuillez-vous rendre sur le portail Mobilic',
                            })}
                        </p>
                    </>
                }
            />
        </CustomDrawer>
    );
});

export default TaskGroupDetailsDrawer;
